.select-container {
  margin-bottom: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ccc;
}

th {
  background-color: #f2f2f2;
}

.info-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.info-box {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f2f2f2;
}

.info-box p {
  margin: 5px 0;
}
