.left-menu {
    overflow-y: auto;

    .offcanvas-body {
        padding: 0;

        .singular-item {
            background-color: #222831;
            border-bottom: 1px solid #000;
            color: #fff !important;
            padding: 10px 10px 10px 5px;
        }
    }

    .sidebar-item {
        cursor: pointer;
        display: block;
        color: #fff;

        span {
            line-height: 35px;
        }


    }

    .open {
        .sidebar-content {
            .singular-item {
                padding: 10px 10px 10px 30px !important;
            }

            .sidebar-item {
                .sidebar-title {
                    span {
                        padding-left: 15px;
                    }

                }
            }
        }
    }

    .sidebar-title {
        background-color: #222831;
        display: flex;
        justify-content: space-between;
        color: #ececec;
        padding: 10px;

        &:hover {
            opacity: 0.9;
        }

        svg {
            border: 1px solid #6c757d;
            border-radius: 20px;
        }
    }
    
    .sidebar-item.open>.sidebar-title .MuiSvgIcon-root {
        transform: rotate(180deg);
    }

    .sidebar-content {
        border-top: 1px solid #000;
        height: 0;
        overflow: hidden;
    }

    .sidebar-item.open>.sidebar-content {
        height: auto;
    }
    
    .sidebar-item {
        &.plain {
            color: #000;
            text-decoration: none;
            padding: 10px;
            &:hover {
                opacity: 0.9;
            }
        }
    }
}