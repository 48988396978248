.dashboard {
    a {
        color: #000;
    }
}
.dashboard-box-group {
    position: relative;
    a {
        color: #000;
    }
}

.MuiPaper-root {
    p {
       margin-bottom: 0;
    }
}