@import '~bootstrap/scss/bootstrap.scss';


@include media-breakpoint-up(xxl) {
    .sidebar-border-radius {
      width: 60%;
    }
}

.topbar-border {
    border-width: 7px 0 0;
    border-top-style: solid;
    -o-border-image: linear-gradient(139deg, #03C988, #1C82AD, #00337C, #03C988) 3;
    border-image: linear-gradient(139deg, #03C988, #1C82AD, #00337C, #03C988) 3;
}

.border-top-primary {
    border-top: 1px solid $primary;
}

.border-top-secondary {
    border-top: 1px solid $secondary;
}

.border-top-success {
    border-top: 1px solid $success;
}

.border-top-warning {
    border-top: 1px solid $warning;
}

.border-top-danger {
    border-top: 1px solid $danger;
}

.border-top-dark {
    border-top: 1px solid $dark;
}